import React, { Component } from "react";
import Greeting from "../../components/greeting/Greeting";
import Footer from '../../components/footer/Footer';


class Home extends Component {
  render() {
    return (
      <div>
        
        <Greeting theme={this.props.theme} />
        <Footer />        
      </div>
    );
  }
}

export default Home;
