// src/components/header/Header.js
import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // CSS stillerini buraya ekleyebilirsiniz

export default function Header() {
  return (
    <header className="header">
      <nav className="navbar">
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link to="/" className="navbar-link">Home</Link>
          </li>
          <li className="navbar-item">
            <Link to="/blog" className="navbar-link">Blog</Link>
          </li>
          <li className="navbar-item">
            <Link to="/about-us" className="navbar-link">About Us</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
