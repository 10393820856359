import React from "react";
import "./Greeting.css";
import { motion } from "framer-motion";

export default function Greeting(props) {
  const theme = props.theme || {}; // Eğer `theme` undefined ise boş bir obje olarak varsayalım
  const greeting = {
    title: "Welcome to AI Lab",
    logo_name: "AI Lab",
    subTitle:
      "At AI Lab, our mission is to develop innovative AI models tailored for special projects of companies. Our team is dedicated to pushing the boundaries of artificial intelligence to deliver unique solutions that meet the specific needs of our clients. Whether it's enhancing images, automating tasks, or providing insightful data analysis, AI Lab is committed to excellence and innovation.",
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text" style={{ color: theme.text || "#000" }}>
                {greeting.title}
              </h1>
              {greeting.nickname && (
                <h2 className="greeting-nickname" style={{ color: theme.text || "#000" }}>
                  ( {greeting.nickname} )
                </h2>
              )}
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText || "#555" }}
              >
                {greeting.subTitle}
              </p>
            </div>
          </div>
          <div className="greeting-image-div">
            <img
              alt="AI Lab"
              src={require("../../assets/images/AI-No-Background.png")}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
